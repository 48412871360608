export default {
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_KT0IaNseT',
    APP_CLIENT_ID: '73jv4jcd4f9auur10f1anb5qrh',
    BUCKET: 'zipin-shop',
    ACCESS_KEY: 'YOUR_ACCESS_KEY' /** We don't need */,
    SECRET_KEY: 'YOUR_SECRET_KEY' /** We don't need */,
  },
};
