// ** Razorpay API Key
export const RAZORPAY_KEYS = {
  KEY_ID: 'rzp_live_0Hq67Nck5KVIOJ',
  // KEY_ID: 'rzp_test_N5glucX9WiSTfV',
};

// ** AWS configuration
export const COGNITO_POOL_CLIENT_ID = {
  userPoolId: 'ap-south-1_Huw6oD3GS',
  clientId: '73jv4jcd4f9auur10f1anb5qrh',
};

export const AWS_CONFIG = {
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_Huw6oD3GS',
    APP_CLIENT_ID: '73jv4jcd4f9auur10f1anb5qrh',
    BUCKET: 'atomtrip',
    ACCESS_KEY: 'YOUR_ACCESS_KEY' /** We don't need */,
    SECRET_KEY: 'YOUR_SECRET_KEY' /** We don't need */,
  },
};

// ** Host API
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// ** Endpoint API
function getFullUrl(endPoint, endPointPath) {
  // // ** For AWS Production
  return `https://vq5oft3hx8.execute-api.ap-south-1.amazonaws.com/atprd/${endPoint}/${endPointPath}`;

  // ** For AWS Development
  //return `https://vq5oft3hx8.execute-api.ap-south-1.amazonaws.com/atdev/${endPoint}/${endPointPath}`;
}

function getSwaggerFullUrl(endPoint, endPointPath) {
  // ** For Swagger Development
  return `http://3.111.177.36:9003/dev/api/${endPoint}/${endPointPath}`;

  // ** For AWS Development
  //return `https://vq5oft3hx8.execute-api.ap-south-1.amazonaws.com/atdev/${endPoint}/${endPointPath}`;
}

export const AWS_API_ENDPOINTS = {
  user: {
    CREATE_NEW_USER: getFullUrl('user', 'signup'),
    REQUEST_NEW__OTP: getFullUrl('user', 'generateotp'),
    VALIDATE_OTP_AND_CHANGE_PASSWORD: getFullUrl('user', 'forgotPassword'),
    GET_USER_BY_EMAIL: getFullUrl('user', 'byemail'),
    UPDATE_USER_MOBILE_NUMBER: getFullUrl('user', 'updtcontact'),
    VERIFY_OTP: getFullUrl('user', 'verifyotp'),
  },
  shop: {
    GET_SHOP_BY_USER_ID: getFullUrl('shops', 'byuser'),
    UPDATE_AND_CREATE_SHOP_DETAILS: getFullUrl('shops', ''),
    GET_UPDATE_CREATE_SHOP_ADDRESS_BY_SHOP_ID: getFullUrl('shops', 'address'),
    GET_ALL_SHOP_CUSTOMERS_BY_ID: getFullUrl('shops', 'cust'),
    UPLOAD_IMAGE: getFullUrl('shops', 'upload'),
    FEEDBACK_SUBMISSION: getFullUrl('email', 'send'),
  },
  travel: {
    GET_ALL_QUOTATION_BY_SHOP_ID: getFullUrl('travel', 'quote/all'),
    GET_UPDATE_AND_CREATE_QUOTATION_DETAILS: getFullUrl('travel', 'quote'),
    GET_HOTELS_BY_CITY: getFullUrl('travel', 'hotel/search'),
  },
  subscription: {
    GET_ALL_PLANS: getFullUrl('subs', 'all'),
    CREATE_TOPUP_NEW_PLAN: getFullUrl('subs/order', 'topup'),
    CREATE_FREE_NEW_PLAN: getFullUrl('subs/order', 'free'),
    CREATE_RP_SUBSCRIPTION_NEW_PLAN: getFullUrl('subs/order', 'subs'),
    GET_RP_SUBSCRIPTION_PLAN: getFullUrl('/subs', 'order'),
    UPDATE_TOPUP_AND_RP_SUBSCRIPTION_PLAN: getFullUrl('subs/order', 'updpayment'),
    GET_ALL_PLANS_HISTORY: getFullUrl('subs/order', 'all'),
  },
};
